import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Input,
  Button,
  Flex,
  Avatar,
  Center,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { _GroupProps, _RoomProps } from '../../client';
import ContactItemDetailed from '../Common/ContactItemDetailed';
import { useUpdateRoomMutation } from '../../store/roomApi';
import { useUpdateGroupMutation } from '../../store/groupApi';
import { useDispatch } from 'react-redux';
import { setActiveRoom } from '../../store/roomSlice';

interface RoomSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  group: _GroupProps;
  room: _RoomProps;
}

const RoomSettings: React.FC<RoomSettingsProps> = ({ isOpen, onClose, group, room }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [roomName, setRoomName] = useState(room.name);
  const [groupName, setGroupName] = useState(group.name);
  const [avatarUrl, setAvatarUrl] = useState(group.avatarUrl);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [updateRoom] = useUpdateRoomMutation();
  const [updateGroup] = useUpdateGroupMutation();

  // Create a ref to focus the input field when switching to edit mode
  const inputRef = useRef<HTMLInputElement>(null);
  const groupInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsEditing(false);
    setIsEditingGroup(false);
    setRoomName(room.name);
    setGroupName(group.name);
    setAvatarUrl('');
  }, [isOpen]);

  const handleSave = async () => {
    setIsEditing(false);
    if (roomName.trim() === "" || roomName.trim() === room.name) {
      setRoomName(room.name);
      return;
    }
    // Check if the room name has changed
    if (roomName !== room.name) {
      try {
        // Attempt to update the room with the new name
        const updatedRoom = await updateRoom({ ...room, name: roomName }).unwrap();
        console.log('Room name updated successfully:', updatedRoom);

        // Dispatch the updated room to the Redux store
        dispatch(setActiveRoom({ ...room, name: roomName }));
      } catch (error) {
        console.error('Failed to update room name', error);
      }
    }
  };

  const handleSaveGroup = async () => {
    let shouldUpdate = true
    setIsEditingGroup(false);
    // Invalid Group Name Change
    if ((groupName === group.name || groupName.trim() === "" || groupName.trim() === group.name)) {
      setGroupName(group.name);
      shouldUpdate = shouldUpdate && false
    }
    // Invalid Avatar Change
    if ((group.avatarUrl === avatarUrl) || (! avatarUrl)){
      setAvatarUrl(group.avatarUrl)
      if (!shouldUpdate)
        return;
    }
    

    try {
      // Attempt to update the group with the new name
      const updatedGroup = await updateGroup({
        group_id: group.id,
        groupUpdate: { ...group, name: groupName, avatar_url: avatarUrl },
      }).unwrap();
      console.log('Group name updated successfully:', updatedGroup);
    } catch (error) {
      console.error('Failed to update group name', error);
    }
  };

  // Function to handle Enter key press to save
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleGroupKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveGroup();
    }
  };

  // Focus the input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditingGroup && groupInputRef.current) {
      groupInputRef.current.focus();
    }
  }, [isEditingGroup]);

  return (
    <>
      {/* Right side: Settings Button */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Chat Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={6}>
              <Heading mb={1} size={"md"}> Room: </Heading>
              <Flex direction="row" align="center" width="100%">
                {isEditing ? (
                  <>
                    <Input
                      ref={inputRef}  // Attach the ref to the Input
                      value={roomName}
                      onChange={(e) => setRoomName(e.target.value)}
                      placeholder="Enter room name"
                      size="sm"
                      width="calc(100% - 80px)"  // Make input take up most of the space
                      onKeyDown={handleKeyDown} // Trigger save on Enter key press
                    />
                    <Button
                      variant={"primary"}
                      ml={2}
                      size="sm"
                      onClick={handleSave}
                      width="70px"  // Set width for the Save button
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Text mr={2} style={{ wordWrap: 'break-word', flexGrow: 1 }}>{roomName}</Text>  {/* Text field with wrapping */}
                    <Button
                      size="sm"
                      variant={"primary"}
                      onClick={() => setIsEditing(true)}
                      width="70px"  // Set width for the Edit button
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
            <Box mb={6}>
              <Heading mb={2} size={"md"}> Group: </Heading>
              <Flex direction="row" align="center" width="100%">
                <Box position="relative" 
                  cursor={isEditingGroup ? "pointer" : "default"}
                  onClick={() => isEditingGroup ? fileInputRef.current?.click() : null }
                  borderRadius="full"
                  mr={4}
                >
                  <Avatar size="md" src={avatarUrl || group.avatarUrl || ""} bg="gray.100">
                    {isEditingGroup && (
                      <Center position="absolute" top="0" left="0" right="0" bottom="0">
                        <AddIcon boxSize={4} color="gray.500" />
                      </Center>
                    )}
                  </Avatar>
                  <Input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => setAvatarUrl(reader.result as string);
                        reader.readAsDataURL(file);
                      }
                    }}
                    hidden
                  />
                </Box>
                {isEditingGroup ? (
                  <>
                    <Input
                      ref={groupInputRef}
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      placeholder="Enter group name"
                      size="sm"
                      width="calc(100% - 80px)"
                      onKeyDown={handleGroupKeyDown}
                    />
                    <Button
                      variant={"primary"}
                      ml={2}
                      size="sm"
                      onClick={handleSaveGroup}
                      width="70px"
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Text mr={2} style={{ wordWrap: 'break-word', flexGrow: 1 }}>{group.name}</Text>
                    <Button
                      size="sm"
                      variant={"primary"}
                      onClick={() => setIsEditingGroup(true)}
                      width="70px"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
            <Box mb={4}>
              <Heading size={"md"}> Group Members </Heading>
              {group.members.map((member) => (
                <Box my={0} key={member.id}>
                  <ContactItemDetailed
                    onClick={() => { }}
                    user={member}
                  />
                </Box>
              ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoomSettings;
