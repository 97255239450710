import React, { useState, useRef, useEffect } from 'react';
import { Box, Text, useColorModeValue, Input } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { _RoomProps } from '../../client/models/_RoomProps';
import { setActiveRoom } from '../../store/roomSlice';
import { RootState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateRoomMutation } from '../../store/roomApi';
import { useGetUnreadMessagesCountQuery } from '../../store/userRoomStateApi';  // Import the query

interface RoomItemProps {
  room: _RoomProps;
  collapseDrawer: () => void;
}

const RoomItem: React.FC<RoomItemProps> = ({ room, collapseDrawer }) => {
  const bgColor = useColorModeValue('ui.light.b1', 'ui.dark.b1');
  const activeColor = useColorModeValue('ui.light.b3', 'ui.dark.b2');
  const hoverColor = useColorModeValue('ui.light.b2', 'ui.dark.b3');

  const activeRoom = useSelector((state: RootState) => state.room.activeRoom);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [roomName, setRoomName] = useState(room.name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [updateRoom] = useUpdateRoomMutation();

  // Fetch unread messages count using the API
  const { data: unreadMessagesCount, isLoading: unreadMessagesLoading } = useGetUnreadMessagesCountQuery({ room_id: room.id });

  collapseDrawer; // suppress warning but want this here for future

  const roomClicked = () => {
    handleRoomSelect(room);
  };

  const handleRoomSelect = (roomKey: _RoomProps) => {
    dispatch(setActiveRoom(roomKey));
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (roomName.trim() === "" || roomName.trim() === room.name) {
      setRoomName(room.name);
      return;
    }
    if (roomName !== room.name) {
      try {
        await updateRoom({ ...room, name: roomName }).unwrap();
        dispatch(setActiveRoom({ ...room, name: roomName }));
      } catch (error) {
        console.error('Failed to update room name', error);
      }
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleBlur();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setRoomName(room.name);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const unreadCount = unreadMessagesCount?.unread_messages_count || room.unreadCount || 0

  return (
    <Link to="/workspace">
      <Box
        bg={room.id === (activeRoom?.id || '') ? activeColor : bgColor}
        borderRadius="md"
        cursor="pointer"
        _hover={{ bg: hoverColor }}
        onClick={roomClicked}
        onDoubleClick={handleDoubleClick}
      >
        {isEditing ? (
          <Input
            rounded={5}
            ref={inputRef}
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            size="md"
          />
        ) : (
          <Box display="flex" alignItems="center" pl={4}>
            <Box display="flex" w="20px" h="20px">
              <Box
                m="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
                backgroundColor={unreadMessagesLoading ? 'gray.500' : unreadCount === 0 ? 'gray.500' : 'red.500'}
                color="white"
                fontSize="xs"
                // fontWeight="bold"
                minW={unreadCount === 0 ? '5px' : '20px'}
                h={unreadCount === 0 ? '5px' : '20px'}
                mr={2}
              >
                {(unreadMessagesLoading || unreadCount == 0) ? '' : unreadCount}
              </Box>
            </Box>
            <Text ml={2} fontSize="md">{room.name}</Text>
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default RoomItem;
