import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MessageRead, UserOut } from '../client';
import baseQuery from './base';

type OptimisticMessage = MessageRead & {
    status: 'pending' | 'succeeded' | 'failed';
}

let connection_pool : Record<string, WebSocket> = {};
const connect_to_room = function(roomId: string):WebSocket {
  if (roomId in connection_pool){
    return connection_pool[roomId]
  } else {
    const baseUrl = import.meta.env.VITE_API_URL.split('//')[1];
    const protocol = window.location.protocol.includes('https') ? 'wss://' : 'ws://';
    const token = localStorage.getItem('access_token');
    const wsUrl = `${protocol}${baseUrl}/api/v1/rooms/ws/chat/${roomId}?token=${token}`;
    const ws = new WebSocket(wsUrl);
    connection_pool[roomId] = ws
    return ws
  }
};
const remove_connection = function(roomId:string) {
  console.log("remove_connection called")
  if (roomId in connection_pool){
    const ws = connection_pool[roomId];
    try{
      ws.close()
    }finally{
      delete connection_pool[roomId];
    }
  }
}

// // Define the WebSocket API
export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['Messages'],
  endpoints: (builder) => ({
    getMessages: builder.query<OptimisticMessage[], { roomId: string, offset: number }>({
        query: ({ roomId, offset })=> ({
          url: `/api/v1/messages/`,
          method: 'GET',
          params: { "room_id" : roomId, 
                    "offset" : offset,
                    "limit"  : 40 },
        }),
        merge: (currentCache = [], incomingData) => {
          // This is a frontend gaurantee that duplicate msgs are not received, but this should
          // be prevented elsewhere first
          const updatedCache = [...currentCache];
          incomingData.forEach(incomingMessage => {
            const existingIndex = updatedCache.findIndex(cachedMessage => cachedMessage.id === incomingMessage.id);
            if (existingIndex !== -1) {
              updatedCache[existingIndex] = incomingMessage;
            } else {
              updatedCache.push(incomingMessage);
            }
          });
          updatedCache.sort((a, b) => (a.created_at||'').localeCompare(b.created_at || ''));
          return updatedCache;
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { roomId } = queryArgs;
          // omits `offset` from query cache
          return { roomId }; 
        },
        async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved }) {
          let ws: WebSocket;
          try {
            ws = connect_to_room(arg.roomId)
            console.log("Connected to room")
            ws.onmessage = (event) => {
              console.log("Received a message", event)
              const message: OptimisticMessage = { ...JSON.parse(event.data), status: 'succeeded' };
              updateCachedData((draft) => {
                if (!draft.some(msg => msg.id === message.id)){
                  message.is_read = false
                  draft.push(message)
                  draft.sort((a, b) => (a.created_at||'').localeCompare(b.created_at || ''));
                }
              })
            };
            await cacheEntryRemoved;
            console.log("Disconnected from room")
          } catch (err) {
            console.error("Error in WebSocket handling:", err);
          }finally {
            remove_connection(arg.roomId);
          }
        },
        providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Messages' as const, id })),
              { type: 'Messages', id: 'LIST' },
            ]
          : [{ type: 'Messages', id: 'LIST' }],
    }),
    sendMessage: builder.mutation<MessageRead, { roomId: string; message: string; user: UserOut }>({
      query: ({ roomId, message, user }) => ({
          url: `/api/v1/messages/`,
          method: 'POST',
          body: { 
            room_id: roomId, 
            content: message, 
            sender_id: user.id
          },
      }),
      async onQueryStarted({ roomId, message, user }, { dispatch, queryFulfilled }) {
          const tempId = (new Date()).toISOString() // tempID also acts as a datetime for local sort
          const optimisticMessage: OptimisticMessage = {
              id: tempId,
              room_id: roomId,
              content: message,
              sender_id: user.id,
              internal_model_id: null,
              created_at: tempId,
              status: 'pending',
              is_read: true
          };
          console.log("Set status to Pending")


          // Optimistically update cache
          dispatch(
            chatApi.util.updateQueryData('getMessages', { roomId, offset: 0 }, (draft) => {
              console.log("Pushed message to local cache", draft)
              draft.push(optimisticMessage);
            })
          );

          try {
            const { data: response } = await queryFulfilled;
              console.log("Received response, updated pending to success")
              // Update the status to succeeded
              dispatch(
                  chatApi.util.updateQueryData('getMessages', { roomId, offset: 0 }, (draft) => {
                      const message = draft.find((msg) => msg.id === tempId);
                      console.log("Set status to succeeded")
                      if (message) {
                          message.status = 'succeeded';
                          message.id = response.id
                          message.created_at = response.created_at
                      }
                  })
              );
              const ws = connect_to_room(roomId);
              ws.send(JSON.stringify(response));
          } catch {
              // Revert optimistic update on error
              dispatch(
                  chatApi.util.updateQueryData('getMessages', { roomId, offset: 0 }, (draft) => {
                      console.log("Set status to Failed")
                      const message = draft.find((msg) => msg.id === tempId);
                      if (message) {
                          message.status = 'failed';
                      }
                  })
              );
          }
      },
      // invalidatesTags: [{ type: 'Messages', id: 'LIST' }],
    }),
  }),
});

export const { useGetMessagesQuery, useSendMessageMutation } = chatApi;

export default chatApi;
