// src/components/AdminBar.tsx
import React, { useState } from 'react';
import { HStack, IconButton, Link, Tooltip } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FaExpandAlt, FaCompressAlt } from 'react-icons/fa';

import NewGroupModal from './NewGroupModal';
import ManageFriendsModal from './ManageFriendsModal';
import { FiSettings } from 'react-icons/fi';


interface AdminBarProps {
  expandOnClick: ()=>void;
  collapseOnClick: ()=>void;
}

const AdminBar: React.FC<AdminBarProps> = ({expandOnClick, collapseOnClick}) => {
  const [isNewGroupOpen, setisNewGroupOpen] = useState(false);
  const [isManageFriendsOpen, setIsManageFriendsOpen] = useState(false);

  const handleNewGroup = () => setisNewGroupOpen(true);
  const handleCloseNewGroup = () => setisNewGroupOpen(false);
  // const handleOpenManageFriends = () => setIsManageFriendsOpen(true);
  const handleCloseManageFriends = () => setIsManageFriendsOpen(false);

  const onAddGroup = () => {
    handleNewGroup();
  };

  return (
    <>
      <HStack spacing={2} mb={4} justify={'space-between'}>
        <HStack justify={'flex-start'}>
          <Tooltip label="Add Group" aria-label="Add Group">
            <IconButton
              icon={<AddIcon />}
              variant="ghost"
              colorScheme='orange'
              onClick={onAddGroup}
              aria-label="Add Group"
            />
          </Tooltip>
          <Tooltip label="Expand All" aria-label="Expand All">
            <IconButton
              icon={<FaExpandAlt />}
              variant="ghost"
              colorScheme='orange'
              onClick={expandOnClick}
              aria-label="Expand All"
            />
          </Tooltip>
          <Tooltip label="Collapse All" aria-label="Collapse All">
            <IconButton
              icon={<FaCompressAlt />}
              variant="ghost"
              colorScheme='orange'
              onClick={collapseOnClick}
              aria-label="Collapse All"
            />
          </Tooltip>
          {/* <Tooltip label="Manage Contacts" aria-label="Manage Contacts">
            <IconButton
              icon={<FaUserFriends />}
              variant="ghost"
              colorScheme='orange'
              onClick={onManageFriends}
              aria-label="Manage Contacts"
            />
          </Tooltip> */}
        </HStack>
        <Tooltip label="Settings" aria-label="Settings">
            <IconButton
              icon={<FiSettings />}
              as={Link}
              href="/settings"
              variant="ghost"
              colorScheme='orange'
              aria-label="Settings"
            />
          </Tooltip>
      </HStack>
      <NewGroupModal isOpen={isNewGroupOpen} onClose={handleCloseNewGroup} />
      <ManageFriendsModal isOpen={isManageFriendsOpen} onClose={handleCloseManageFriends}/>
    </>
  );
};

export default AdminBar;
