import { configureStore } from '@reduxjs/toolkit';
import chatApi from './chatApi';
import { groupAPI } from './groupApi';
import { friendshipAPI } from './friendshipApi';
import { notebookApi } from './notebookApi';
import { userRoomStateApi } from './userRoomStateApi';
import roomReducer from './roomSlice';
import { 
  loadState as loadRoomState, 
  saveState as saveRoomState
} from './roomSlice';
import SSEEventManager from "./SSEEventManager";

// Function to load state from localStorage
const loadState = () => {
  return loadRoomState()
};

// Function to save state to localStorage
const saveState = (state: any) => {
  saveRoomState(state)
};

const persistedState = loadState();


export const store = configureStore({
  reducer: {
    [chatApi.reducerPath]: chatApi.reducer, // API add reducer
    [groupAPI.reducerPath]: groupAPI.reducer,
    [friendshipAPI.reducerPath]: friendshipAPI.reducer,
    [notebookApi.reducerPath]: notebookApi.reducer,
    [userRoomStateApi.reducerPath]: userRoomStateApi.reducer,
    room: roomReducer, // Slice add reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      chatApi.middleware,
      groupAPI.middleware,
      friendshipAPI.middleware,
      notebookApi.middleware,
      userRoomStateApi.middleware,
    ]), // API add middleware
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState(store.getState());
});

// Initialize the SSE connection
const sseManager = SSEEventManager.getInstance();
sseManager.initialize(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
