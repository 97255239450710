import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Avatar,
  AvatarBadge,
  Box,
  Text,
  IconButton,
  Center,
  useToast,
  useSteps
} from '@chakra-ui/react';
import { SmallCloseIcon, AddIcon } from '@chakra-ui/icons';
import { useCreateGroupMutation } from '../../store/groupApi';
import { useQueryClient } from 'react-query';
import { UserOut } from '../../client';
import ContactItem from '../Common/ContactItem';
import ContactItemDetailed from '../Common/ContactItemDetailed';
import { useSearchUsersByQuery } from '../../store/friendshipApi';

interface NewGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewGroupModal: React.FC<NewGroupModalProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData<UserOut>('currentUser');
  if (!currentUser) return null; // Add safeguard in case currentUser isn't loaded.

  const [selectedUsers, setSelectedUsers] = useState<UserOut[]>([currentUser]);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [email, setEmail] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<UserOut[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createGroup] = useCreateGroupMutation();
  const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({ index: 0 });

  // useSearchUsersByQuery hook to fetch users based on search query
  const { data: users, isLoading, isError } = useSearchUsersByQuery(email);

  // Clear form on modal close
  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setSelectedUsers([currentUser]);
      setFilteredContacts([]);
      setGroupName('');
      setGroupDescription('');
      setAvatarUrl('');
      setActiveStep(0);
    }
  }, [isOpen]);

  const handleAddUser = (user: UserOut) => {
    setSelectedUsers([...selectedUsers, user]);
    setEmail('');
    setFilteredContacts(filteredContacts.filter((contact) => contact.id !== user.id));
  };

  const handleRemoveUser = (user: UserOut) => {
    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
  };

  const handleInputChange = (value: string) => {
    setEmail(value);
    if (value.trim() === '') {
      setFilteredContacts([]); // Reset if input is cleared
    } else {
      setFilteredContacts(
        users?.filter((user) => !selectedUsers.some(selectedUser => selectedUser.id === user.id)) || []
      );      
    }
  };

  const handleCreateGroup = async () => {
    try {
      const groupData = {
        name: groupName,
        description: groupDescription,
        avatar_url: avatarUrl,
        member_ids: selectedUsers.map(user => user.id),
      };
      await createGroup(groupData);
      toast({
        title: 'Group created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: unknown) {
      toast({
        title: 'Error creating group.',
        description: (error as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a new Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {activeStep === 0 && (
            <VStack spacing={4} align="start">
              <FormControl>
                <FormLabel>Selected Members</FormLabel>
                <VStack align="start">
                  {selectedUsers.map((user) => (
                    <ContactItem
                      key={user.id}
                      user={user}
                      showRemoveButton={user.id!== currentUser.id}
                      onRemove={() => handleRemoveUser(user)}
                    />
                  ))}
                </VStack>
              </FormControl>

              <FormControl>
                <FormLabel>Add members to the group</FormLabel>
                <HStack spacing={2}>
                  <Input
                    placeholder="Enter name, username, email, or phone number"
                    value={email}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                </HStack>
              </FormControl>

              <VStack align="start" spacing={1} w="full" maxH="200px" overflowY="auto">
                {isLoading && <Text>Loading...</Text>}
                {isError && <Text color="red.500">Error fetching users</Text>}
                {filteredContacts && filteredContacts.length > 0 ? (
                  filteredContacts.map((contact) => (
                    <ContactItemDetailed
                      key={contact.id}
                      user={contact}
                      onClick={() => handleAddUser(contact)}
                      clickable={true}
                    />
                  ))
                ) : (
                  <Text color="gray.500">No contacts found</Text>
                )}
              </VStack>
            </VStack>
          )}

          {activeStep === 1 && (
            <VStack spacing={4} align="start">
              <HStack w="full" spacing={4} align="center">
                <FormControl w="auto">
                  <FormLabel>Group Avatar</FormLabel>
                  <Box 
                    position="relative" 
                    cursor="pointer" 
                    onClick={() => fileInputRef.current?.click()}
                    borderRadius="full"
                  >
                    <Avatar size="lg" src={avatarUrl} bg="gray.100">
                      {!avatarUrl && (
                        <Center position="absolute" top="0" left="0" right="0" bottom="0">
                          <AddIcon boxSize={6} color="gray.500" />
                        </Center>
                      )}
                      {avatarUrl && (
                        <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          icon={<SmallCloseIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAvatarUrl('');
                          }}
                        />
                      )}
                    </Avatar>
                    <Input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => setAvatarUrl(reader.result as string);
                          reader.readAsDataURL(file);
                        }
                      }}
                      hidden
                    />
                  </Box>
                </FormControl>
                <FormControl flex={1}>
                  <Input
                    placeholder="Enter group name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </FormControl>
              </HStack>

              <FormControl my={5}>
                <Input
                  placeholder="Enter group description"
                  value={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)}
                />
              </FormControl>

              <Box w="full">
                <Text fontWeight="bold" mb={2}>Members:</Text>
                <VStack align="start">
                  {selectedUsers.map((user) => (
                    <HStack key={user.id} spacing={3}>
                      <Avatar size="sm" src={user.avatarUrl ?? undefined} />
                      <Text>{user.full_name || user.email}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Box>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          {activeStep > 0 && (
            <Button variant="ghost" mr={3} onClick={() => setActiveStep(activeStep - 1)}>
              Back
            </Button>
          )}
          {activeStep < 1 ? (
            <Button variant="primary" onClick={() => setActiveStep(activeStep + 1)}>
              Next
            </Button>
          ) : (
            <Button variant="primary" onClick={handleCreateGroup} isDisabled={!groupName}>
              Create Group
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewGroupModal;
