import React, { useState, useRef } from 'react';
import ChatArea from './ChatArea';
import Notebook from './Notebook';
import { Flex, Box, Tabs, TabList, TabPanels, Tab, TabPanel, useMediaQuery } from '@chakra-ui/react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useGetGroupsQuery } from '../../store/groupApi';
import { getGroupByRoomId } from './utils';

const Workspace: React.FC = () => {
  const defaultChatWidth = 50; // Default width for ChatArea in percentage
  const [chatWidth, setChatWidth] = useState(defaultChatWidth); // Percentage width for ChatArea
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)'); // Responsive check for small screens

  const activeRoom = useSelector((state: RootState) => state.room.activeRoom);
  const { data: groups } = useGetGroupsQuery();
  const activeGroup = getGroupByRoomId(activeRoom?.id || '', groups?.groups || []);

  // Start dragging
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  // Adjust width while dragging
  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;
    const newChatWidth = containerRef.current
      ? ((e.clientX - containerRef.current.offsetLeft) / containerRef.current.clientWidth) * 100
      : defaultChatWidth;
    if (newChatWidth >= 25 && newChatWidth <= 75) {
      setChatWidth(newChatWidth);
    }
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Attach and clean up event listeners for mouse movements
  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  if (!activeRoom || !activeGroup) {
    return (
      <Box width="100%" height="100%" textAlign="center" alignContent="center">
        Select a chat to start messaging
      </Box>
    );
  }

  return (
    <Flex height="100%" width="100%" overflowX="hidden" ref={containerRef}>
      {isSmallScreen ? (
        <Tabs
          variant="enclosed"
          // colorScheme="orange"
          width="100%"
          height={"100%"}
        >
          <TabList height="5%" justifyContent="center">
            <Tab>Chat</Tab>
            <Tab>Notebook</Tab>
          </TabList>
          <TabPanels pt={4} height="95%">
            <TabPanel height={"100%"} p={0}>
              <ChatArea activeRoom={activeRoom} activeGroup={activeGroup} />
            </TabPanel>
            <TabPanel height={"100%"} p={0}>
              <Notebook allocatedWidth={100} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <>
          <Box width={`${chatWidth}%`} height="100%" overflowX="hidden">
            <ChatArea activeRoom={activeRoom} activeGroup={activeGroup} />
          </Box>
          <Box
            width="3px"
            height="100%"
            cursor="col-resize"
            bg="gray.300"
            onMouseDown={handleMouseDown}
            overflowX="hidden"
          ></Box>
          <Box width={`${100 - chatWidth}%`} height="100%" overflowX="hidden">
            <Notebook allocatedWidth={100 - chatWidth} />
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Workspace;
