import React, { useRef, useEffect, useState } from 'react';
import { Avatar, VStack, Text, HStack, Badge, Flex, Button } from '@chakra-ui/react';
import { FaRobot } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import { useMarkMessageReadMutation } from '../../store/userRoomStateApi';

interface MessageItemProps {
  id: string;
  roomId: string;
  sender: string;
  message: string;
  avatarUrl?: string;
  isBot?: boolean;
  showSenderInfo?: boolean;
  isRead: boolean;
  status: 'pending' | 'succeeded' | 'failed';
}

const MessageItem: React.FC<MessageItemProps> = ({
  id,
  roomId,
  sender,
  message,
  avatarUrl,
  isBot,
  showSenderInfo = true,
  isRead = true,
  status
}) => {
  const [markMessageRead] = useMarkMessageReadMutation();
  const messageRef = useRef<HTMLDivElement>(null);

  // We only want to mark the message as read one time
  const [hasRead, setHasRead] = useState(isRead);
  const [isVisible, setIsVisible] = useState(true);
  const [isFocus, setIsFocus] = useState(true);

  useEffect(() => {
    const refElement = messageRef.current;
    if (!refElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // `isIntersecting` indicates if the element (message bottom) is in view
          setIsVisible(entry.isIntersecting);
          if (isVisible && !hasRead && isFocus) {
            markMessageRead({ message_id: id, room_id: roomId });
            setHasRead(true);
            observer.unobserve(entry.target);  // Unobserve so we only mark as read once
          }
        });
      },
      {
        // Adjust the threshold if you only want to mark as read
        // when the message is fully visible (e.g., `threshold: 1.0`)
        threshold: 0.1,
      }
    );

    observer.observe(refElement);

    const handleFocus = () => {
        setIsFocus(true);
        if (isVisible && !hasRead && isFocus){
          markMessageRead({ message_id: id, room_id: roomId });
          setHasRead(true);
        }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('focusout', ()=>{setIsFocus(false)})

    // Cleanup
    return () => {
      if (refElement) {
        observer.unobserve(refElement);
      }
      document.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <HStack
      align="flex-start"
      spacing="4"
      w="full"
      pt={showSenderInfo ? 2 : 0}
      borderRadius="md"
      ref={messageRef}  // Attach the ref here
    >
      <Avatar
        opacity={showSenderInfo ? 100 : 0}
        name={sender}
        src={avatarUrl}
        height={showSenderInfo ? "40px" : "0px"}
        width="40px"
        icon={isBot ? <FaRobot /> : undefined}
      />

      <VStack align="stretch" spacing="0" w="full">
        <HStack>
          <Text
            hidden={!showSenderInfo}
            fontWeight="bold"
            fontSize="md"
            fontStyle={isBot ? "italic" : "normal"}
          >
            {sender}
          </Text>
          {isBot && (
            <Badge colorScheme="teal" fontSize="xs">
              AI
            </Badge>
          )}
        </HStack>
        <Flex width="90%">
          <Text
            as="span"
            color={
              status
                ? status === 'pending'
                  ? 'gray.400'
                  : status === 'failed'
                  ? 'red'
                  : undefined
                : undefined
            }
          >
            <Markdown remarkPlugins={[remarkGfm]}>
              {message}
            </Markdown>
          </Text>
          {status === 'failed' && (
            <Button my="auto" ml="auto" color="red" size="xs" bg="transparent">
              <IoMdRefresh />
            </Button>
          )}
        </Flex>
      </VStack>
    </HStack>
  );
};

export default MessageItem;
