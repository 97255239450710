import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Badge,
  Text,
  Flex,
  Switch,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, MoonIcon } from '@chakra-ui/icons';

import { useQueryClient } from 'react-query';

import AdminBar from './AdminBar';
import { UsersService, UserOut } from '../../client';
import LogoText from '../Common/LogoText';

import {Group} from './types';

import { Link } from '@tanstack/react-router';
import GroupList from './GroupList';
import { useGetGroupsQuery } from '../../store/groupApi';
import useAuth from '../../hooks/useAuth';



const GroupDrawer: React.FC = () => {
  const { logout } = useAuth()

  const handleBgHoverColor = useColorModeValue('ui.light.main5', 'ui.dark.main5');
  const handleBgColor = useColorModeValue('ui.light.main', 'ui.dark.main');
  const handleColor = useColorModeValue('ui.light.dark', 'ui.light.dark');
  const dividerBg = useColorModeValue('ui.light.dark', 'ui.dark.b3');
  const drawerBg = useColorModeValue('ui.light.b1', 'ui.dark.b1');
  const drawerHiddenBg = useColorModeValue('ui.light.b2', 'ui.dark.b2');

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => {
    const storedIds = localStorage.getItem('expandedGroupIds');
    return storedIds ? JSON.parse(storedIds) : [];
  });
  const queryClient = useQueryClient();
  const { data: groups } = useGetGroupsQuery();

  const groupsList = groups?.groups || [];  

  const currentUser = queryClient.getQueryData<UserOut>('currentUser');
  const { colorMode, toggleColorMode } = useColorMode();

  const toggleDrawer = () => setIsCollapsed(!isCollapsed);

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [feedback, setFeedback] = useState('');

  const handleOpenFeedbackModal = () => setIsFeedbackModalOpen(true);
  const handleCloseFeedbackModal = () => setIsFeedbackModalOpen(false);

  const handleFeedback = () => {
    UsersService.feedback({ feedback });
    handleCloseFeedbackModal();
  };

  const drawerWidth = useBreakpointValue({ base: '70vw', md: '350px' });

  // Toggle the expanded group
  const toggleGroupExpand = (group: Group) => {
    setExpandedGroupIds((prev) => {
      const updatedIds = prev.includes(group.id)
        ? prev.filter((id) => id !== group.id)
        : [...prev, group.id];
      return updatedIds;
    });
  };

  useEffect(() => {
    localStorage.setItem('expandedGroupIds', JSON.stringify(expandedGroupIds));
  }, [expandedGroupIds])

  useEffect(() => {
    const storedIds = localStorage.getItem('expandedGroupIds');
    if (storedIds) {
      setExpandedGroupIds(JSON.parse(storedIds));
    }
  }, []);

  return (
    <Box bg={isCollapsed ? drawerHiddenBg : drawerBg}>
      {/* Handle for collapsed drawer */}
      <Box
        position="fixed"
        left={isCollapsed ? '0' : drawerWidth} // Dynamically calculate based on drawer width
        top="50%"
        transform="translateY(-50%)"
        bg={handleBgColor}
        color={handleColor}
        width="22px"
        height="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="0 25px 25px 0"
        cursor="pointer"
        zIndex="1000"
        onClick={toggleDrawer}
        _hover={{ bg: handleBgHoverColor }}
      >
        <Flex align="center" justify="center" width="100%">
          {isCollapsed ? (
            <ChevronRightIcon transform={'translateX(+2px)'} w={6} h={6} />
          ) : (
            <ChevronLeftIcon transform={'translateX(+5px)'} w={6} h={6} />
          )}
        </Flex>
        {groupsList.reduce((acc, group) => acc + (group.unreadCount || 0), 0) > 0 && (
          <Badge
            fontSize="md"
            colorScheme="red"
            transform={'translateY(-20px)'}
            borderRadius="full"
            mb="auto"
          >
            {groupsList.reduce((acc, group) => acc + (group.unreadCount || 0), 0)}
          </Badge>
        )}
      </Box>

      {/* Drawer */}
      <Flex
        height="100vh"
        direction="column"
        width={isCollapsed ? '0' : drawerWidth} // Use the dynamic width for the drawer
        transition="width 0.1s"
        overflowX="hidden"
        p={4}
      >
        {!isCollapsed && groupsList && currentUser?.email && (
          <>
            {/* Top Segment */}
            <Box>
              <Link to="/">
                <Box width={"60%"} mr={"auto"} mb={7}>
                  <LogoText />
                </Box>
              </Link>
              <AdminBar 
                expandOnClick={() => setExpandedGroupIds(groupsList.map(group => group.id))}
                collapseOnClick={() => setExpandedGroupIds([])}
              />
              <Divider mx="auto" borderColor={dividerBg} my={3} width={'90%'} />
            </Box>

            {/* Middle Segment */}
            <Box
              flex="1"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '0px', // Hide scrollbar
                },
              }}
            >
              <GroupList
                groups={groupsList}
                onSelectGroup={toggleGroupExpand}
                expandedGroupIds={expandedGroupIds}
                onRoomClick={toggleDrawer}
              />
            </Box>

            {/* Bottom Segment */}
            <Box>
              <Flex flexDir="column" mb={5}>
                <Divider mx="auto" borderColor={dividerBg} my={3} width={'90%'} />
                <Switch mx="auto" onChange={toggleColorMode} isChecked={colorMode === 'dark'}>
                  <MoonIcon height={'100%'} mb={2} />
                </Switch>
                <Text
                  color="blue.500"
                  fontSize="sm"
                  p={2}
                  mx={'auto'}
                  onClick={handleOpenFeedbackModal}
                  _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Provide Feedback
                </Text>
                <Button
                  variant={"primary"}
                  mx="auto"
                  onClick={() => logout()}
                >
                  Logout
                </Button>
              </Flex>
            </Box>
          </>
        )}
      </Flex>

      {/* Feedback Modal */}
      <Modal isOpen={isFeedbackModalOpen} onClose={handleCloseFeedbackModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody minW="400px" minH="400px">
            <Textarea
              value={feedback}
              width="100%"
              minW="400px"
              minH="400px"
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter your feedback"
              size="lg"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" mr={3} onClick={handleFeedback}>
              Submit
            </Button>
            <Button variant="ghost" onClick={handleCloseFeedbackModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GroupDrawer;
