import { _RoomProps, RoomCreate, RoomRead } from '../client';
import { groupAPI } from './groupApi';

export const roomAPI = groupAPI.injectEndpoints({
  endpoints: (builder) => ({
    // getRooms: builder.query<_RoomProps, void>({
    //   query: () => ({
    //     url: '/api/v1/rooms/',
    //     method: 'GET'
    //   }),
    //   transformErrorResponse: (response: any) => response as _RoomProps,
    //   providesTags: ['Room'],
    // }),
    createRoom: builder.mutation<RoomRead, RoomCreate>({
      query: (roomData: RoomCreate) => ({
        url: '/api/v1/rooms/',
        method: 'POST',
        body: roomData
      }),
      invalidatesTags: ['Group']
    }),
    updateRoom: builder.mutation<RoomRead, _RoomProps>({
      query: (roomData: _RoomProps) => ({
        url: `/api/v1/rooms/${roomData.id}/`,
        method: 'PUT',
        body: roomData
      }),
      invalidatesTags: ['Group']
    }),
    // deleteRoom: builder.mutation<{ success: boolean; id: number }, number>({
    //   query: (id: number) => ({
    //     url: `/api/v1/rooms/${id}/`,
    //     method: 'DELETE'
    //   }),
    //   invalidatesTags: ['Room']
    // })
  }),
})

export const { 
  // useGetRoomsQuery, 
  useCreateRoomMutation, 
  useUpdateRoomMutation, 
  // useDeleteRoomMutation 
} = roomAPI;
