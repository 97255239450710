import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Spinner, useToast, Text } from '@chakra-ui/react';
import MessageItem from './MessageItem';
import { useGetMessagesQuery } from '../../store/chatApi';
import { useGetGroupsQuery } from '../../store/groupApi';
import { _GroupProps, _RoomProps, ModelsService } from '../../client';
import { getNumMessagesInRoom } from './utils';
import { useQuery } from 'react-query';
import { LogoMap } from '../../utils';

interface MessageListProps {
  activeRoom: _RoomProps,
  activeGroup: _GroupProps
}

const MessageList: React.FC<MessageListProps> = ({ activeRoom, activeGroup }) => {
  const roomId = activeRoom?.id || '';
  const messageListRef = useRef<HTMLDivElement | null>(null);
  const [isThrottled, setIsThrottled] = useState(false);

  const { isLoading: groupsLoading } = useGetGroupsQuery();
  const members = activeGroup?.members;

  const { data: groups } = useGetGroupsQuery();
  const numTotalMessages = getNumMessagesInRoom(groups?.groups, roomId);

  const [offset, setOffset] = useState(0);
  const { data: messages = [], isLoading, error, refetch } = useGetMessagesQuery({
    "roomId": roomId,
    "offset": offset
  });

  const toast = useToast();

  const handleScroll = () => {
    setOffset(messages.length);
    if (messageListRef.current) {
      if (messageListRef.current.scrollTop < messageListRef.current.scrollHeight / 4) {
        if (messages.length < numTotalMessages) {
          if (!isThrottled) {
            setIsThrottled(true);
            refetch();
            setTimeout(() => {
              setIsThrottled(false);
            }, 500);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [roomId, messages]);

  useEffect(() => {
    setOffset(0); // Reset offset when activeRoom changes
  }, [roomId]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messageListRef.current) {
        messageListRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const internalModelsQuery = useQuery('internal_models', () => ModelsService.readInternalModels({}))
  const internalModels = internalModelsQuery.data?.data || []

  const renderMessages = useCallback(() => {
    let prevSender: string | null | undefined = null;
    let dividerRendered = false;  // Track if divider has been inserted

    return messages?.map((msg, index) => {
      let senderName, senderAvatar, showSenderInfo

      if (msg.is_ai) {
        const userMsg = internalModels?.find(model => model.id === msg.internal_model_id);
        senderName = userMsg?.name || '';
        senderAvatar = userMsg?.company ? LogoMap(userMsg.company) : '';
        showSenderInfo = index === 0 || msg.internal_model_id !== prevSender;
      }
      else {
        const userMsg = members?.find(member => member.id === msg.sender_id);
        senderName = userMsg?.full_name || userMsg?.username || userMsg?.email || '';
        senderAvatar = userMsg?.avatarUrl || '';
        showSenderInfo = index === 0 || msg.sender_id !== prevSender;
      }

      prevSender = msg.sender_id || msg.internal_model_id;


      // Render divider between last read message and first unread message
      if (!dividerRendered && !msg.is_read) {
        dividerRendered = true; // Prevent rendering divider more than once
        return (
          <>
            <Box textAlign="center" mt={4}>
              <Box borderBottom="1px solid red" width="70%" mx="auto" />
              <Text fontSize={"xs"} color="red" mt={0}>Unread Messages</Text>
            </Box>
            <MessageItem
              id={msg.id ?? ""}
              roomId={roomId}
              sender={senderName}
              avatarUrl={senderAvatar}
              message={msg.content}
              showSenderInfo={showSenderInfo}
              status={msg.status}
              isBot={msg.is_ai}
              isRead={msg.is_read ?? true}
            />
          </>
        );
      }

      return (
        <MessageItem
          id={msg.id ?? ""}
          roomId={roomId}
          sender={senderName}
          avatarUrl={senderAvatar}
          message={msg.content}
          showSenderInfo={showSenderInfo}
          status={msg.status}
          isBot={msg.is_ai}
          isRead={msg.is_read ?? true}
        />
      );
    });
  }, [messages, messages.flatMap((msg)=>{msg.is_read})]);

  // Error Toast Notification
  if (error) {
    toast({
      title: 'Error loading messages',
      description: (error as any).toString() || 'Something went wrong.',
      status: 'error',
      isClosable: true,
    });
  }

  return (
    <>
      {(!members?.map || isLoading || groupsLoading ) ? <Spinner /> :
        <Box ref={messageListRef} flex="1" p="4" overflowY="scroll" ml={3} onScroll={handleScroll}>
          {renderMessages()}
        </Box>
      }
    </>
  );
};

export default MessageList;
