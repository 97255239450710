import React, { useState } from 'react';
import { Flex, Box, Text, IconButton, Tooltip, Spinner } from '@chakra-ui/react';
import { GoKebabHorizontal } from 'react-icons/go';
import { useGetGroupsQuery } from '../../store/groupApi';
import { useColorModeValue } from '@chakra-ui/react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import RoomSettings from '../Modals/RoomSettings';

interface ChatHeaderProps {
  onSettingsClick?: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({}) => {
  const activeRoom = useSelector((state : RootState) => state.room.activeRoom);
  const { data, isLoading: groupsLoading, isError } = useGetGroupsQuery();
  const [isRoomSettingsModalOpen, setRoomSettingsModalOpen] = useState(false);

  const groups = data?.groups || [];

  const roomName = activeRoom?.name;
  let group;
  let groupName = '';
  let number_members = 1;

  if (groups && activeRoom) {
    group = groups.find((group) => group.rooms.some((room) => room.id === activeRoom?.id));
    groupName = group?.name || '';
    number_members = group?.members.length || 1
  }

  if (isError) {
    groupName = 'Error loading group data';
  }

  const handleCenterClick = () => {
    setRoomSettingsModalOpen(true);
  };

  const handleCloseModal = () => {
    setRoomSettingsModalOpen(false);
  };

  return (
    <Flex
      align="center"
      // justify="space-between"
      p={3}
      border="1px solid"
      borderColor={useColorModeValue("ui.light.b1", "ui.dark.b1")}
    >
      {(groupsLoading) && <Spinner />}
      {/* Left side: Room Name */}
      <Text mr="auto" fontSize="md" fontWeight="bold" width="33%" overflowWrap="break-word">
        {roomName}
      </Text>

      {/* Center: Group Info */}
      <Box mx="auto" onClick={handleCenterClick} width="33%" cursor="pointer" textAlign="center">
        <Text fontSize="md" fontWeight="bold" >
            {groupName}
        </Text>
        <Text fontSize="sm" color="gray.500">
            {number_members} members
        </Text>
      </Box>

      {/* Right side: Settings Button */}
      <Box width="33%" textAlign="right">
        <Tooltip label="Room Settings" aria-label="Room Settings">
          <IconButton
            // opacity={"0%"}
            icon={<GoKebabHorizontal />}
            aria-label="Room Settings"
            variant="ghost"
            onClick={() => {setRoomSettingsModalOpen(true)}}
          />
        </Tooltip>
      </Box>
      {activeRoom &&
        <RoomSettings
          isOpen={isRoomSettingsModalOpen}
          onClose={handleCloseModal}
          group={group}
          room={activeRoom}
        />
      }
    </Flex>
  );
};

export default ChatHeader;
