import { Dispatch } from "@reduxjs/toolkit";
import { groupAPI } from "./groupApi";
import { userRoomStateApi } from "./userRoomStateApi";
import { chatApi } from "./chatApi";
import { friendshipAPI } from "./friendshipApi";
import { notebookApi } from "./notebookApi";
import { roomAPI } from "./roomApi";
type SSECallback = (data: any) => void;

class SSEEventManager {
  private static instance: SSEEventManager;
  private eventSource: EventSource | null = null;
  private listeners: Map<string, SSECallback[]> = new Map();
  private reduxDispatch: Dispatch | null = null;
  private url: string;

  private constructor() {
    this.url = import.meta.env.VITE_API_URL + "/api/v1/sse/"
  }

  public static getInstance(): SSEEventManager {
    if (!SSEEventManager.instance) {
      SSEEventManager.instance = new SSEEventManager();
    }
    return SSEEventManager.instance;
  }

  /**
   * Initialize the SSE Manager with Redux dispatch and the connection URL
   */
  public initialize(dispatch: Dispatch): void {
    if (this.eventSource) {
      console.warn("SSE connection already initialized.");
      return;
    }

    this.reduxDispatch = dispatch;
    this.eventSource = new EventSource(this.url);

    console.log("Registered")

    this.eventSource.onmessage = (event) => {
      const data = event.data
      const eventType = data.type || "default";

      // Notify registered listeners for this event type
      this.notify(eventType, data);

      // TODO: Dispatch Redux actions to invalidate cache tags
      if (this.reduxDispatch) {
        switch (data) {
          case "Group":
            this.reduxDispatch(
              groupAPI.util.invalidateTags(["Group"])
            );
            break;
          case "UserRoomState":
            this.reduxDispatch(
              userRoomStateApi.util.invalidateTags(['UserRoomState'])
            );
            break;
          case "Chat":
             this.reduxDispatch(
              chatApi.util.invalidateTags([{ type: "Messages", id: "LIST" }])
            );
            break;
          case "Friendship":
            this.reduxDispatch(
              friendshipAPI.util.invalidateTags(['Friendship', 'PendingFriends'])
            );
            break;
          case "Notebook":
            this.reduxDispatch(
              notebookApi.util.invalidateTags(["Notebook", "Cell"])
            );
            break;
          case "Room":
            this.reduxDispatch(
              roomAPI.util.invalidateTags(["Group", "Room"])
            );
            break;
          default:
            console.warn(`Unhandled event type: ${eventType}`);
        }
      }
    };

    this.eventSource.onerror = () => {
      console.error("SSE connection lost. Reconnecting...");
      this.disconnect();
      setTimeout(() => this.initialize(dispatch), 10000); // Reconnect after 5 seconds
    };
  }

  /**
   * Disconnect the SSE connection
   */
  public disconnect(): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  /**
   * Subscribe to a specific event type
   */
  public subscribe(eventType: string, callback: SSECallback): void {
    if (!this.listeners.has(eventType)) {
      this.listeners.set(eventType, []);
    }
    this.listeners.get(eventType)!.push(callback);
  }

  /**
   * Unsubscribe from a specific event type
   */
  public unsubscribe(eventType: string, callback: SSECallback): void {
    if (this.listeners.has(eventType)) {
      const updatedListeners = this.listeners
        .get(eventType)!
        .filter((listener) => listener !== callback);
      this.listeners.set(eventType, updatedListeners);
    }
  }

  /**
   * Notify listeners of a specific event type
   */
  private notify(eventType: string, data: any): void {
    const callbacks = this.listeners.get(eventType) || [];
    callbacks.forEach((callback) => callback(data));
  }
}

export default SSEEventManager;
