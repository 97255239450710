// src/components/GroupItem.tsx
import React, { useState } from 'react';
import { Box, Text, Flex, Avatar, Collapse, VStack, Tooltip, Menu, MenuButton, MenuList, MenuItem, IconButton, useColorModeValue } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon, AddIcon } from '@chakra-ui/icons';
// import { FaUser } from 'react-icons/fa';
import { GoKebabHorizontal } from "react-icons/go";
import RoomItem from './RoomItem';
import GroupSettingsModal from './GroupSettingsModal';
import { _RoomProps } from '../../client/models/_RoomProps';
import { useCreateRoomMutation } from '../../store/roomApi';


interface GroupItemProps {
  group: {
    id: string;
    name: string;
    avatarUrl?: string | null;
    latestMessage?: string;
    unreadCount?: number | null;
    rooms: _RoomProps[];
  };
  isExpanded: boolean;
  onToggleExpand: () => void;
  onRoomClick: () => void;
}

const GroupItem: React.FC<GroupItemProps> = ({ group, isExpanded, onToggleExpand, onRoomClick }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Modal state for settings
  const [createRoom] = useCreateRoomMutation()

  // const handleOpenSettings = (e) => {
  //   e.stopPropagation()
  //   setIsSettingsOpen(true);
  // }
  const handleCloseSettings = () => setIsSettingsOpen(false);
  const handleNewRoom = (e) => {
    e.stopPropagation()
    createRoom({
      group_id: group.id, 
      name: "New Room"}
    )
  };

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        cursor="pointer"
        // p={3}
        // bg={isExpanded ? activeColor : bgColor}
        borderRadius="md"
        onClick={onToggleExpand}
      >
        <Flex align="center">
          {/* Group Avatar */}
          {group.avatarUrl ? (
            <Avatar src={group.avatarUrl} size="sm" mr={3} />
          ) : (
            <Box
              borderRadius="50%"
              backgroundColor={useColorModeValue("ui.light.b3", "ui.dark.main")}
              color={useColorModeValue("ui.light.dark", "ui.dark.light")}
              width="32px"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              borderColor={"black"}
              mr={3}
            >
              <Text fontSize="sm" fontWeight="bold">
                {group.name
                  .split(" ")
                  .slice(0, 2)
                  .map((word) => word[0])
                  .join("")
                  // .toUpperCase()
                  .substring(0, 2)}
              </Text>
            </Box>
          )}
          <Text fontWeight={(group.unreadCount ?? 0) > 0 ? "bold" : "medium"}>{group.name}</Text>
        </Flex>

        <Flex align="center">
          {/* Settings Button */}
          <Tooltip label="Group Settings" aria-label="Group Settings">
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<GoKebabHorizontal />}
                colorScheme="gray"
                variant="ghost"
                aria-label="Settings"
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList zIndex={9999}>
                <MenuItem onClick={handleNewRoom} iconSpacing={4} icon={<AddIcon />}>New Room</MenuItem>
                {/* <MenuItem onClick={handleOpenSettings} iconSpacing={4} icon={<FaUser />}>Add Members</MenuItem> */}
              </MenuList>
            </Menu>
          </Tooltip>
          {/* Chevron for expanding/collapsing */}
          {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Flex>
      </Flex>

      {/* Rooms List (Collapsed/Expanded) */}
      <Collapse in={isExpanded} animateOpacity>
        <VStack spacing={2} align="stretch" mt={2}>
          {group.rooms.map((room) => (
            <RoomItem
              room={room}
              key={room.id}
              collapseDrawer={onRoomClick}
            />
          ))}
        </VStack>
      </Collapse>

      {/* Group Settings Modal */}
      <GroupSettingsModal
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        groupName={group.name}
        groupId={group.id}
      />
    </Box>
  );
};

export default GroupItem;
