import React from 'react';
import { HStack, Avatar, Box, Text, useColorModeValue } from '@chakra-ui/react';
import { UserOut } from '../../client';

interface ContactItemDetailedProps {
  user: UserOut
  clickable?: Boolean
  onClick: () => void;
}

const ContactItemDetailed: React.FC<ContactItemDetailedProps> = ({ user, clickable=false, onClick }) => {
  return (
    <HStack
      key={user.id}
      spacing={3}
      w="full"
      p={2}
      borderRadius="md"
      _hover={{ bg: clickable ? useColorModeValue('gray.100', 'gray.800') : "default"}}
      cursor={clickable ? "pointer":"default"}
      onClick={onClick}
    >
      <Avatar size="sm" src={user.avatarUrl ?? undefined} />
      <Box>
        <Text fontWeight="bold">{user.full_name || user.username || user.email}</Text>
        <Text fontSize="sm" color="gray.500">
          {user.email}
        </Text>
      </Box>
    </HStack>
  );
};

export default ContactItemDetailed;
