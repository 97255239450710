import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseQuery from './base';
import { chatApi } from './chatApi';

export const userRoomStateApi = createApi({
  reducerPath: 'userRoomStateApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['UserRoomState'],
  endpoints: (builder) => ({
    markMessageRead: builder.mutation<any, { room_id: string, message_id: string }>({
      query: ({ room_id, message_id }) => ({
        url: `/api/v1/user_room_state/mark_message_read/${room_id}/${message_id}`,
        method: 'POST',
      }),
      async onQueryStarted({ room_id, message_id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            chatApi.util.updateQueryData('getMessages', { roomId: room_id, offset: 0 }, (draft) => {
              const message = draft.find((msg) => msg.id === message_id);
              if (message) {
                message.is_read = true;
              }
            })
          );
        } catch {
          // No need to revert optimistic update on error
        }
      },
    }),
    getUnreadMessagesCount: builder.query<any, { room_id: string }>({
      query: ({ room_id }) => ({
        url: `/api/v1/user_room_state/unread_messages_count/${room_id}`,
        method: 'GET',
      }),
      providesTags: ['UserRoomState'],
    }),
    getLastReadMessageId: builder.query<any, { room_id: string }>({
      query: ({ room_id }) => ({
        url: `/api/v1/user_room_state/last_read_message/${room_id}`,
        method: 'GET',
      }),
      providesTags: ['UserRoomState'],
    }),
  }),
});

export const { 
  useMarkMessageReadMutation,
  useGetUnreadMessagesCountQuery,
  useGetLastReadMessageIdQuery,  // Export the query hook for the last read message ID
} = userRoomStateApi;
