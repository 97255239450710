import React, { useState, useRef, useEffect } from 'react';
import {
  HStack,
  Image,
  Text,
  VStack,
  useToast,
  Box,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { useQuery, useQueryClient } from 'react-query';
import { UserOut } from '../../client/models/UserOut';
import { useSendMessageMutation } from '../../store/chatApi';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { IoIosSend } from 'react-icons/io';
import { LogoMap } from '../../utils';
import { InternalModelOut, ModelsService } from '../../client';

const MessageInput: React.FC = () => {
  const [message, setMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toast = useToast();
  const activeRoom = useSelector((state: RootState) => state.room.activeRoom);
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData<UserOut>('currentUser');
  const [sendMessage] = useSendMessageMutation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const selectableColor = useColorModeValue('ui.light.main', 'ui.dark.main');
  const subTextColor = useColorModeValue('ui.light.dark', 'ui.dark.light');
  const bgColor = useColorModeValue('ui.light.b3', 'ui.dark.b3')

  const { data: internalModelsQuery } = useQuery('internal_models', () =>
    ModelsService.readInternalModels({})
  );
  const menuItems = internalModelsQuery?.data || [];
  function reorderModelsByCompany(models: InternalModelOut[]): InternalModelOut[] {
    // First, create a map to record the first index appearance of each company.
    const companyOrder: Record<string, number> = {};

    models.forEach((model, index) => {
        if (companyOrder[model.company] === undefined) {
            companyOrder[model.company] = index;
        }
    });

    // Now, sort the array based on the initial index of each model's company.
    return models.slice().sort((a, b) => {
        return companyOrder[a.company] - companyOrder[b.company];
    });
  }

  // Filter menu items based on the search query
  const filteredMenuItems = reorderModelsByCompany(menuItems).filter((item) =>
    item.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
    item.company.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
    item.openai_name.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  // Array of refs for menu items
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Close the popup if there are no search results
    if (searchQuery && filteredMenuItems.length === 0) {
      setIsPopupOpen(false);
    }
  }, [searchQuery, filteredMenuItems]);

  // Scroll to the selected index
  useEffect(() => {
    const selectedItem = itemRefs.current[selectedIndex];
    if (selectedItem) {
      selectedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  const handleSend = () => {
    if (message.trim() && activeRoom && currentUser) {
      try {
        sendMessage({ roomId: activeRoom.id, message, user: currentUser });
        setMessage('');
      } catch (error: any) {
        toast({
          title: 'Error sending message',
          description: error.message || 'An unexpected error occurred.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (isPopupOpen) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prev) => (prev + 1) % filteredMenuItems.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex((prev) => (prev - 1 + filteredMenuItems.length) % filteredMenuItems.length);
          break;
        case 'Enter':
          e.preventDefault();
          handleSelectAI(filteredMenuItems[selectedIndex].name);
          break;
        case 'Escape':
          e.preventDefault();
          setIsPopupOpen(false);
          break;
        case ' ':
          e.preventDefault();
          setIsPopupOpen(false);
          break;
      }
    } else if (e.key === '@') {
      setIsPopupOpen(true);
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);

    // Detect the "@" symbol and update the search query
    const match = value.match(/@(\S*)$/);
    if (match) {
      setSearchQuery(match[1]);
      if (filteredMenuItems.length > 0) {
        setIsPopupOpen(true);
      }
    } else {
      setSearchQuery('');
      setIsPopupOpen(false);
    }
  };

  const handleSelectAI = (aiName: string) => {
    setMessage((prevMessage) => `${prevMessage.replace(/@\S*$/, '')}@"${aiName}" `);
    setSearchQuery('');
    setIsPopupOpen(false);
    textareaRef.current?.focus();
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (textareaRef.current && !textareaRef.current.contains(event.target as Node)) {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isPopupOpen]);

  return (
    <HStack p="4" pb="6" mt="auto" flexShrink={0} position="relative">
      <TextareaAutosize
        ref={textareaRef}
        placeholder="Start typing or ask an @ai"
        value={message}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        minRows={1}
        maxRows={13}
        style={{
          width: '100%',
          resize: 'none',
          padding: '14px',
          borderRadius: '8px',
          border: '1px solid #ccc',
        }}
      />
      {/* <IconButton
        size="lg"
        aria-label="tag"
        height="100%"
        variant="primary"
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        disabled={!activeRoom || !currentUser}
      >
        <FaAt />
      </IconButton> */}
        <Box position="absolute" bottom="100%" right="0" width="60%" zIndex="10">
        {isPopupOpen && (
          <Box
            maxHeight="300px"
            overflowY="auto"
            border="1px solid #ccc"
            borderRadius="md"
            boxShadow="md"
          >
            {filteredMenuItems.map((model, index) => (
              <Box
                ref={(el) => (itemRefs.current[index] = el)}
                key={model.id}
                px="4"
                py="2"
                bg={index === selectedIndex ? 'gray.400' : bgColor}
                cursor="pointer"
                onClick={() => handleSelectAI(model.name)}
              >
                <HStack>
                  <Image width="30px" src={LogoMap(model.company)} alt={model.company} />
                  <VStack align="left" spacing={0}>
                    <Text color={selectableColor} fontWeight="bold">
                      {model.name}
                    </Text>
                    <Text fontSize="xs" color={subTextColor}>
                      {model.parameter_count + ' Parameters'}
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <IconButton
        size="lg"
        aria-label="send"
        height="100%"
        variant="primary"
        onClick={handleSend}
        disabled={!activeRoom || !currentUser}
      >
        <IoIosSend />
      </IconButton>
    </HStack>
  );
};

export default MessageInput;
