import React from 'react';
import { HStack, Avatar, Text, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { UserOut } from '../../client';

interface ContactItemProps {
  user: UserOut
  showRemoveButton?: boolean;
  isCurrentUser?: boolean;
  onRemove?: () => void;
}

const ContactItem: React.FC<ContactItemProps> = ({ user, showRemoveButton, isCurrentUser, onRemove }) => {
  return (
    <HStack key={user.id} spacing={3} w="full">
      <Avatar size="sm" src={user.avatarUrl ?? undefined} />
      <Text flex="1">
        {user.full_name ? user.full_name : user.email}
      </Text>
      {showRemoveButton && !isCurrentUser && onRemove && (
        <IconButton
          icon={<CloseIcon />}
          size="xs"
          aria-label="Remove user"
          onClick={onRemove}
        />
      )}
    </HStack>
  );
};

export default ContactItem;
